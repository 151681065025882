var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.AddEditdialog),callback:function ($$v) {_vm.AddEditdialog=$$v},expression:"AddEditdialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t(_vm.formTitle)))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",staticClass:"mt-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.errMessage)?_c('div',[_c('p',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errMessage))])]):_vm._e()]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Name")))]),_c('v-text-field',{attrs:{"rules":[
                  _vm.validationRules.required,
                  _vm.validationRules.maxLength50,
                ],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}}),(_vm.validation_errors.name)?_c('div',_vm._l((_vm.validation_errors.name),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{staticStyle:{"padding-right":"0 !important"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Mark")))]),_c('v-text-field',{attrs:{"disabled":_vm.editedItem.confirmed,"rules":[_vm.validationRules.required, _vm.validationRules.numeric],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.mark),callback:function ($$v) {_vm.$set(_vm.editedItem, "mark", $$v)},expression:"editedItem.mark"}}),(_vm.validation_errors.mark)?_c('div',_vm._l((_vm.validation_errors.mark),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Date From")))]),_c('v-text-field',{staticClass:"dateIcon",attrs:{"id":"exam_date_from","autocomplete":"off","placeholder":_vm.$i18n.t('Enter date'),"solo":"","rules":[
                  _vm.validationRules.required,
                  _vm.startDateRule(
                    _vm.editedItem.exam_date_from,
                    _vm.editedItem.exam_date_to,
                    'From should be less than to'
                  ),
                ],"append-icon":"event"},on:{"keydown":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();},"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.exam_date_from),callback:function ($$v) {_vm.$set(_vm.editedItem, "exam_date_from", $$v)},expression:"editedItem.exam_date_from"}}),(_vm.validation_errors.exam_date_from)?_c('div',_vm._l((_vm.validation_errors.exam_date_from),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Date To")))]),_c('v-text-field',{staticClass:"dateIcon",attrs:{"id":"exam_date_to","autocomplete":"off","placeholder":_vm.$i18n.t('Enter date'),"solo":"","rules":[
                  _vm.validationRules.required,
                  _vm.startDateRule(
                    _vm.editedItem.exam_date_from,
                    _vm.editedItem.exam_date_to,
                    'To should be more than from'
                  ),
                ],"append-icon":"event"},on:{"keydown":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();},"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.exam_date_to),callback:function ($$v) {_vm.$set(_vm.editedItem, "exam_date_to", $$v)},expression:"editedItem.exam_date_to"}}),(_vm.validation_errors.exam_date_to)?_c('div',_vm._l((_vm.validation_errors.exam_date_to),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-row',[_c('v-col',{staticStyle:{"padding-right":"0 !important","position":"relative"},attrs:{"cols":"6","sm":"6","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Duration")))]),_c('v-text-field',{attrs:{"solo":"","min":"0","type":"number","autocomplete":"off","placeholder":_vm.$i18n.t('hr')},on:{"focus":function($event){return _vm.onFocus()},"blur":function($event){return _vm.onBlurHr(_vm.editedItem.duration_hr)},"change":function($event){return _vm.validateDuration(
                        _vm.editedItem.duration_hr,
                        _vm.editedItem.duration_min
                      )}},model:{value:(_vm.editedItem.duration_hr),callback:function ($$v) {_vm.$set(_vm.editedItem, "duration_hr", $$v)},expression:"editedItem.duration_hr"}})],1),_c('v-col',{staticStyle:{"padding-right":"0 !important","position":"relative"},attrs:{"cols":"6","sm":"6","md":"6"}},[_c('label',{staticStyle:{"visibility":"hidden"}},[_vm._v(_vm._s(_vm.$t("Duration")))]),_c('v-text-field',{attrs:{"autocomplete":"off","solo":"","type":"number","max":"59","min":"0","placeholder":_vm.$i18n.t('min')},on:{"focus":function($event){return _vm.onFocus()},"blur":function($event){return _vm.onBlurMin(_vm.editedItem.duration_min)},"change":function($event){return _vm.validateDuration(
                        _vm.editedItem.duration_hr,
                        _vm.editedItem.duration_min
                      )}},model:{value:(_vm.editedItem.duration_min),callback:function ($$v) {_vm.$set(_vm.editedItem, "duration_min", $$v)},expression:"editedItem.duration_min"}}),_c('span',{staticClass:"betweencolon"},[_vm._v(":")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('p',{staticClass:"error--text text-center"},[_vm._v(_vm._s(_vm.validationMsg))])])],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"modal-btn-cancel cancelBtn",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Close")))]),_c('v-btn',{staticClass:"modal-btn-save saveBtn",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }