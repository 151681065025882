<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row>
              <v-col cols="12" sm="12" md="12" style="text-align: center">
                <div v-if="errMessage">
                  <p class="red--text">{{ errMessage }}</p>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label>{{ $t("Name") }}</label>
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  solo
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
                style="padding-right: 0 !important"
              >
                <label>{{ $t("Mark") }}</label>
                <v-text-field
                  v-model="editedItem.mark"
                  :disabled="editedItem.confirmed"
                  :rules="[validationRules.required, validationRules.numeric]"
                  solo
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.mark">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.mark"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <label>{{ $t("Date From") }}</label>
                <v-text-field
                  id="exam_date_from"
                  autocomplete="off"
                  :placeholder="$i18n.t('Enter date')"
                  class="dateIcon"
                  solo
                  v-model="editedItem.exam_date_from"
                  :rules="[
                    validationRules.required,
                    startDateRule(
                      editedItem.exam_date_from,
                      editedItem.exam_date_to,
                      'From should be less than to'
                    ),
                  ]"
                  append-icon="event"
                  @keydown.prevent
                  @paste.prevent
                  @drop.prevent
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.exam_date_from">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.exam_date_from"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label>{{ $t("Date To") }}</label>
                <v-text-field
                  id="exam_date_to"
                  autocomplete="off"
                  :placeholder="$i18n.t('Enter date')"
                  class="dateIcon"
                  solo
                  v-model="editedItem.exam_date_to"
                  :rules="[
                    validationRules.required,
                    startDateRule(
                      editedItem.exam_date_from,
                      editedItem.exam_date_to,
                      'To should be more than from'
                    ),
                  ]"
                  append-icon="event"
                  @keydown.prevent
                  @paste.prevent
                  @drop.prevent
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.exam_date_to">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.exam_date_to"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                    style="padding-right: 0 !important; position: relative"
                  >
                    <label>{{ $t("Duration") }}</label>
                    <v-text-field
                      v-model="editedItem.duration_hr"
                      solo
                      min="0"
                      type="number"
                      @focus="onFocus()"
                      @blur="onBlurHr(editedItem.duration_hr)"
                      autocomplete="off"
                      @change="
                        validateDuration(
                          editedItem.duration_hr,
                          editedItem.duration_min
                        )
                      "
                      :placeholder="$i18n.t('hr')"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                    style="padding-right: 0 !important; position: relative"
                  >
                    <label style="visibility: hidden">{{
                      $t("Duration")
                    }}</label>
                    <v-text-field
                      v-model="editedItem.duration_min"
                      autocomplete="off"
                      solo
                      type="number"
                      max="59"
                      min="0"
                      @focus="onFocus()"
                      @blur="onBlurMin(editedItem.duration_min)"
                      @change="
                        validateDuration(
                          editedItem.duration_hr,
                          editedItem.duration_min
                        )
                      "
                      :placeholder="$i18n.t('min')"
                    ></v-text-field>
                    <span class="betweencolon">:</span>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <p class="error--text text-center">{{ validationMsg }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import moment from "moment";
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
  ],
  mixins: [validationMixin],
  name: "AddEditAdmissionExamComponent",
  data() {
    return {
      menu2: false,
      valid: false,
      validationMsg: "",
      durationValid: false,
      validation_errors: {
        name: "",
        mark: "",
        exam_date_from: "",
        exam_date_to: "",
      },
      loading: false,
      errMessage: "",
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      },
    },
  },
  methods: {
    // startDateRule(start, end) {
    //   var startDate = moment(start, "DD/MM/YYYY");
    //   var endDate = moment(end, "DD/MM/YYYY");
    //   return startDate >= endDate ? "From should be less than To" : true;
    // },
    validateDuration(hr, min) {
      hr = Number(hr);
      min = Number(min);
      if (hr == 0 && min == 0) {
        this.validationMsg = this.$i18n.t("This field is required");
        this.durationValid = false;
      } else {
        this.validationMsg = "";
        this.durationValid = true;
      }
    },
    onBlurMin(min) {
      if (min == "" || !min) {
        this.editedItem.duration_min = "00";
      }
      if (!isNaN(min) && min < 10 && !min.startsWith("0")) {
        this.editedItem.duration_min = "0" + min;
      }
    },
    onBlurHr(hr) {
      if (hr == "" || !hr) {
        this.editedItem.duration_hr = "00";
      }
      if (!isNaN(hr) && hr < 10 && !hr.startsWith("0")) {
        this.editedItem.duration_hr = "0" + hr;
      }
    },
    onFocus() {
      this.validation_errors = {
        name: "",
        mark: "",
        exam_date_from: "",
        exam_date_to: "",
      };
    },
    close() {
      this.validationMsg = "";
      this.durationValid = false;
      this.AddEditdialog = false;
      this.editedItem = {};
      this.validation_errors = {
        name: "",
        mark: "",
        exam_date_from: "",
        exam_date_to: "",
      };
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (this.editedItem.duration_min == "" || !this.editedItem.duration_min) {
        this.editedItem.duration_min = 0;
      }
      if (this.valid == false || this.durationValid == false) {
        this.validate();
        this.validateDuration(
          this.editedItem.duration_hr,
          this.editedItem.duration_min
        );
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.resetValidation();
              this.loading = false;
              if (response.data.status.error == true) {
                Object(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
                if (response.data.status.validation_errors.length == 0) {
                  this.errMessage = response.data.status.message;
                }
              } else {
                this.AddEditdialog = false;
                this.close();
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.resetValidation();
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
                if (response.data.status.validation_errors.length == 0) {
                  this.errMessage = response.data.status.message;
                }
              } else {
                this.editedItem = {
                  name: "",
                  mark: "",
                  exam_date_from: "",
                  exam_date_to: "",
                  duration_hr: "",
                  duration_min: "",
                  online_exam: true,
                };

                let examId = response.data.data.id;

                this.$router.push(
                  {
                    path: "/" + "createOnlineExam/" + examId,
                  },
                  () => {}
                );

                this.close();
              }

              this.loading = false;
            });
        }
        this.validation_errors = {
          name: "",
          subject_id: "",
          qualifier_id: "",
          mark: "",
          exam_date: "",
          academic_year_id: "",
        };
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.space-name {
  margin-top: -15px !important;
}

.betweencolon {
  position: absolute;
  top: 3rem;
  left: 5%;
}

.dur-error {
  margin-top: 0 !important;
}
</style>
